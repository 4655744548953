
import { Component, Vue } from 'vue-property-decorator'
import { Notification, Program } from '@/models/index'

@Component({
  name: 'Notification',
  components: {
    NotificationsHeader: () => import('./components/NotificationsHeader.vue'),
    NotificationCard: () => import('./components/NotificationCard.vue'),
    EditDialog: () => import('./components/EditDialog.vue'),
    Pagination: () => import('@/components/Pagination/index.vue')

  }
})
export default class extends Vue {
  private notifications: Notification[] = []
  private totalNotifications = 0
  private notificationsLoading = false
  private programs: Program[] = []

   private listQuery = {
    page: 1,
    limit: 20,
    kind: 'all',
    programId: undefined
  }

  private notificationSelected = new Notification({ id: '' })
  private showDialog = false

  created() {
    this.getNotifications()
    this.getPrograms()
  }

  private async getNotifications() {
    this.notificationsLoading = true
    const _kind = this.listQuery.kind === 'all' ? undefined : this.listQuery.kind

    const { data, meta } = await Notification
    .includes('programs')
    .page(this.listQuery.page)
    .per(this.listQuery.limit)
    .where({ kind: _kind, programId: this.listQuery.programId })
    .order({ createdAt: 'desc' })
    .stats({ total: 'count' })
    .all()

    this.notifications = data
    this.totalNotifications = meta.stats.total.count
    this.notificationsLoading = false
  }

  private async getPrograms() {
    const { data } = await Program.all()

    this.programs = data
  }

  private refresh() {
    this.getNotifications()
  }
  private create() {
    this.notificationSelected = new Notification({ id: '', kind: 'event' })
    this.showDialog = true
  }

  private edit(notification: Notification) {
    this.notificationSelected = notification
    this.showDialog = true
  }

  private async deleteNotification(notification: Notification, index: number) {
    await notification.destroy()

    this.notifications.splice(index, 1)
  }

  private addOrDeletePrograms(programIds: string[]) {
    this.notificationSelected.programs.forEach(program => {
      if (programIds.find(p => p === program.id) === undefined) {
        program.isMarkedForDisassociation = true
      }
    })

    for (const programId of programIds) {
      const alreadyAssociated = this.notificationSelected.programs.find(p => p.id === programId)

      if (alreadyAssociated !== undefined) alreadyAssociated.isMarkedForDisassociation = false
      else {
        const program = this.programs.find(p => p.id === programId)
        if (program !== undefined) this.notificationSelected.programs.push(program)
      }
    }
  }
}
