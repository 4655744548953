
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MultiChoiceGiftCard } from '@/models'
import { clipboardSuccess } from '@/utils/clipboard'

@Component({
  name: 'MultiChoiceCard'
})
export default class extends Vue {
  @Prop({ required: true }) private multiChoiceGiftCard!: MultiChoiceGiftCard
  @Prop({ required: true }) private index!: number
  @Prop({ required: true }) private total!: number

  private tooltipDelete = false
  private clipboardSuccess = clipboardSuccess

  async handleDelete() {
    await this.multiChoiceGiftCard.destroy()

    this.$emit('delete', this.index)
    this.tooltipDelete = false
    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
  }
}
