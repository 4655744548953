
import { Component, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'
import { MultiChoiceGiftCard } from '@/models'

@Component({
  name: 'MultiChoiceCardsList',
  components: {
    MultiChoiceCard: () => import('./components/MultiChoiceCard.vue'),
    MultiChoiceCardDialog: () => import('./components/MultiChoiceCardDialog.vue')
  }
})
export default class extends Vue {
  userStore: any = useUserStore()

  private multiChoiceCards: MultiChoiceGiftCard[] = []
  private multiChoiceCardsLoading = true
  private totalMultiChoiceCards = 0

  multiChoiceCardToEdit: MultiChoiceGiftCard | any = null
  private showMultiChoiceCardDialog = false

  get caretaker() {
    return this.userStore.model.manageable
  }

  created() {
    this.getPrograms()
  }

  async getPrograms() {
    this.multiChoiceCardsLoading = true
    this.multiChoiceCards = []
    this.totalMultiChoiceCards = 0

    const { data, meta } =
        await MultiChoiceGiftCard
            .includes('giftCards')
            .page(1)
            .per(100)
            .order({ createdAt: 'desc' })
            .stats({ total: 'count' })
            .all()

    this.multiChoiceCards = data
    this.totalMultiChoiceCards = meta.stats.total.count
    this.multiChoiceCardsLoading = false
  }

  addInList(multiChoiceCard: MultiChoiceGiftCard): void {
    this.multiChoiceCards.unshift(multiChoiceCard)
  }

  deleteInList(index: number): void {
    this.multiChoiceCards.splice(index, 1)
    this.multiChoiceCardToEdit = null
  }

  updateInList(multiChoiceCard: MultiChoiceGiftCard): void  {
    const index = this.multiChoiceCards.findIndex((data: MultiChoiceGiftCard) => data.id == multiChoiceCard.id)
    if (index !== -1) this.multiChoiceCards.splice(index, 1, multiChoiceCard.dup())
    this.multiChoiceCardToEdit = null
  }

  updateMultiChoiceCard(multiChoiceCard: MultiChoiceGiftCard): void {
    this.multiChoiceCardToEdit = multiChoiceCard.dup()
    this.showMultiChoiceCardDialog = true
  }
}
