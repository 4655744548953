
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Notification, Program } from '@/models/index'
import { ElForm } from 'element-ui/types/form'
import { VueEditor } from 'vue2-editor'

@Component({
  name: 'EditDialog',
  components: {
    VueEditor: VueEditor,
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private show!: boolean
  @Prop({ required: true }) private notification!: Notification
  @Prop({ required: true }) private programs!: Program

  @Watch('show')
  private onShowChange(show: boolean) {
    if (show) this.programIds = this.notification.programs.map(p => p.id)
  }

  private programIds: any[] = []
  private saveLoading = false

  private rules = {
    title: [{ required: true, message: 'Le titre est requis', trigger: 'blur' }],
    description: [{ required: true, message: 'La description est requise', trigger: 'blur' }],
    programIds: [{ validator: this.validateAtLeastOneProgram, trigger: 'change' }]
  }

  get isCreate() {
    return !this.notification.isPersisted
  }

  private validateAtLeastOneProgram(_rule: never, _value: any, callback: any) {
    if (this.programIds.length === 0) callback(new Error('Selectionnez au moins un programme'))
    else callback()
  }

  private save() {
    (this.$refs.notificationForm as ElForm).validate(async (valid) => {
      if (valid) {
        const wasPersisted = this.notification.isPersisted
        this.saveLoading = true
        await this.notification.save({ with: 'programs.id' })

        this.saveLoading = false
        if (!wasPersisted) this.$emit('created', this.notification)
        this.$emit('close')
      }
    })
  }

  private uploadCallback(file: any, blob: any) {
    // this.fileDetails = file.blob
    this.notification.bannerImage = file.blob.signed_id
    this.notification.bannerImageBlob = file.blob
  }
}
