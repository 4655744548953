
import { Component, Vue } from 'vue-property-decorator'

import MultiChoiceCardsList from '@/views/multi-choice-cards/components/MultiChoiceCardsList.vue'

@Component({
  name: 'MultiChoiceCards',
  components: {
    MultiChoiceCardsList,
    MultiChoiceCardsHeader: () => import('./components/MultiChoiceCardsHeader.vue')
  }
})
export default class extends Vue {
  private refresh(): void {
    (this.$refs.multiChoiceCardsList as MultiChoiceCardsList).getPrograms()
  }
}
