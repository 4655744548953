
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { MultiChoiceGiftCard, GiftCard } from '@/models'
import { Form } from 'element-ui'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'MultiChoiceCardDialog',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private multiChoiceCard!: any

  userStore: any = useUserStore()

  tempData: MultiChoiceGiftCard = new MultiChoiceGiftCard({ caretaker: this.caretaker })

  private giftCards: GiftCard[] = []
  private giftCardsLoading = false
  private paginationGiftCards = {
    page: 1,
    perPage: 999
  }

  private initialGiftCardsState: GiftCard[] = []
  private loading = false

  dialogStatus = 'create'
  private textMap: any = {
    update: 'Mise à jour d\'une carte cadeaux multi-choix',
    create: 'Création d\'une carte cadeaux multi-choix'
  }

  rules: any = {
    name: [
      { required: true, message: 'Le nom de la carte requis', trigger: 'blur' }
    ],
    giftCards: [
      { required: true, message: 'La sélection des enseignes éligibles est requise', trigger: 'blur' }
    ],
    logo: [
      { required: true, message: 'Le visuel de la carte est requis', trigger: 'blur' }
    ],
    bannerImage: [
      { required: true, message: 'Le visuel de la bannière est requis', trigger: 'blur' }
    ]
  }

  @Watch('visible')
  private onVisibleChange(visible: boolean) {
    if (visible) {
      !this.multiChoiceCard ? this.handleCreate() : this.handleUpdate()
    }
  }

  get caretaker() {
    return this.userStore.model.manageable
  }

  created(): void {
    this.getGiftCards()
  }

  private handleCreate() {
    if (this.giftCards.length === 0) this.getGiftCards()

    this.resetTempData()
    this.dialogStatus = 'create'
    this.$nextTick(() => {
      (this.$refs.multiChoiceCardForm as Form).clearValidate()
    })
  }

  private handleUpdate() {
    if (this.giftCards.length === 0) this.getGiftCards()

    this.tempData = this.multiChoiceCard.dup()
    this.initialGiftCardsState = [...this.tempData.giftCards]

    this.dialogStatus = 'update'
    this.$nextTick(() => {
      (this.$refs.multiChoiceCardForm as Form).clearValidate()
    })
  }

  createOrUpdate() {
    (this.$refs.multiChoiceCardForm as Form).validate(async(valid) => {
      this.loading = true
      if (valid) {
        const data: MultiChoiceGiftCard = this.tempData
        this.compareGiftCards(data)

        try {
          await data.save({ with: ['caretaker.id', 'giftCards.id'] })

          if (this.dialogStatus === 'create') {
            this.$emit('created', data)
            this.$notify({
              title: 'Multi choice card created',
              message: 'Multi choice card successfully created',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('update', data)
            this.$notify({
              title: 'Multi choice card updated',
              message: 'Multi choice card successfully updated',
              type: 'success',
              duration: 2000
            })
          }
          this.$emit('close')
        } catch (err) {
          this.loading = false
        }
      }
      this.loading = false
    })
  }

  async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
      .per(this.paginationGiftCards.perPage)
      .page(this.paginationGiftCards.page)
      .order('name')
      .all()

    this.giftCards.push(...data)

    this.giftCardsLoading = false
  }

  handleGiftCardSelect(giftCards: string[]) {
    if (!(this.tempData instanceof MultiChoiceGiftCard)) return

    this.tempData.giftCards = []
    for (const giftCardId of giftCards) {
      let giftCard = this.giftCards.find(giftCard => giftCard.id === giftCardId)
      if (giftCard) this.tempData.giftCards.push(giftCard)
    }
  }

  resetTempData() {
    this.tempData = new MultiChoiceGiftCard({ caretaker: this.caretaker })
  }

  uploadCallback(file: any) {
    this.tempData.logo = file.blob.signed_id
  }

  uploadCallbackBanner(file: any) {
    this.tempData.bannerImage = file.blob.signed_id
  }

  compareGiftCards(multiChoiceGiftCard: MultiChoiceGiftCard) {
    // Dissociate objects not present in final array
    for (const giftCard of this.initialGiftCardsState) {
      const shouldBeDissociate = !multiChoiceGiftCard.giftCards.find(e => e.id === giftCard.id)
      if (shouldBeDissociate) giftCard.isMarkedForDisassociation = true
    }

    // Associate objects not present in initial array
    for (const giftCard of multiChoiceGiftCard.giftCards) {
      const shouldBeAssociate = !this.initialGiftCardsState.find(e => e.id === giftCard.id)
      if (shouldBeAssociate) this.initialGiftCardsState.push(giftCard)
    }

    multiChoiceGiftCard.giftCards = [...this.initialGiftCardsState]
  }
}
