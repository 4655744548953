
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'NotificationCard'
})
export default class extends Vue {
  @Prop({ required: true }) private notification!: any

  private typeColor = {
    event: 'primary',
    info: 'info',
    maintenance: 'danger',
    program: 'warning'
  }

  private titleColor = {
    event: '#137E1D',
    info: '#24667B',
    maintenance: '#701A1A',
    program: '#4F3A5B'
  }

  private titleText = {
    event: 'Évenement',
    info: 'Information',
    maintenance: 'Maintenance',
    program: 'Programme'
  }
}
